import { useSelector } from "react-redux";
import {
  selectSelectedMatches,
  selectActiveMainFilter,
  selectActiveTab,
  selectGeneralFilters,
} from "../store/matchesSlice";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PointsList from "./PointsList";
import {
  selectMatchesPointList,
  selectSelectedPointsIds,
  selectFilteredMatches,
  setMatchList,
  setNavigateToPoint,
  setSelectedPointsIds,
  getFilteredMatches,
} from "../store/videoPlayerSlice";
import { useLoadCompletedMatchPointsQuery } from "../../api/completeMatchesSlice";
import Spinner from "../../../UI/Spinner";
import {
  selectSelectedFilters,
  selectMatchesFilter,
  setExistingFilters,
} from "../store/matchesSlice";
import CustomClipModal from "./CustomClipModal";
import classes from "./PointSelection.module.css";

const PointSelection = (props) => {
  const selectedMatches = useSelector(selectSelectedMatches);
  const selectedPointsIds = useSelector(selectSelectedPointsIds);
  const matchesPointList = useSelector(selectMatchesPointList);
  const filteredMatches = useSelector(selectFilteredMatches);
  const selectedFilters = useSelector(selectSelectedFilters);
  const activeMainFilter = useSelector(selectActiveMainFilter);
  const activeTab = useSelector(selectActiveTab);
  const generalFilters = useSelector(selectGeneralFilters);
  const matchesFilter = useSelector(selectMatchesFilter);

  const [isFiltering, setIsFiltering] = useState(false);
  const [isCustomClipModalOpen, setIsCustomClipModalOpen] = useState(false);
  let selectedMatchIds = selectedMatches.map((m) => m.matchInstanceId);

  const dispatch = useDispatch();
  const { data, isLoading, error, status, isFetching } = useLoadCompletedMatchPointsQuery(selectedMatchIds);

  useEffect(() => {
    if (data) {
      setIsFiltering(true);
      setTimeout(() => {
        let matchList = selectedMatches.map((match) => {
          return {
            ...match,
            pointList: data[match.matchInstanceId] ?? [],
          };
        });

        const updatedGeneralFilters = getUpdateGeneralFilters();

        let newMatchList = matchList.map((match) => {
          let newMatch = { ...match };
          newMatch.pointList = newMatch.pointList.map((point) => {
            let newPoint = { ...point };
            if (point.isHidden || (point.isManuallyAdded && (selectedFilters.length > 0 || updatedGeneralFilters.player != null || updatedGeneralFilters.pressure != null || updatedGeneralFilters.winner != null))) {
              newPoint.isSelected = false;
              return newPoint;
            }

            if (activeTab.level === "point") {
              newPoint.isSelected = true;
              for (const rowFilter of selectedFilters) {
                let pointMatched = false;
                for (const optionFilter of rowFilter.options) {
                  if (
                    rowFilter.type === "category" &&
                    optionFilter.value === point[rowFilter.propertyName]
                  ) {
                    pointMatched = true;
                    break;
                  } else if (
                    rowFilter.type === "range" &&
                    point[rowFilter.propertyName] >= optionFilter.value[0] &&
                    point[rowFilter.propertyName] <= optionFilter.value[1]
                  ) {
                    pointMatched = true;
                    break;
                  }
                }
                if (!pointMatched) {
                  newPoint.isSelected = false;
                  break;
                }
              }
            }
            if (updatedGeneralFilters.winner != null) {
              const selectedPlayerId = updatedGeneralFilters.player[0];

              if (
                (updatedGeneralFilters.winner[0] == 0 &&
                  selectedPlayerId != -1) ||
                (updatedGeneralFilters.winner[0] == 1 && selectedPlayerId == -1)
              )
                newPoint.isSelected =
                  newPoint.isSelected &&
                  newPoint.winnerId == matchesFilter.firstPlayer?.playerId;

              if (
                (updatedGeneralFilters.winner[0] == 1 &&
                  selectedPlayerId != -1) ||
                (updatedGeneralFilters.winner[0] == 0 && selectedPlayerId == -1)
              )
                newPoint.isSelected =
                  newPoint.isSelected &&
                  newPoint.winnerId != matchesFilter.firstPlayer?.playerId;
            }

            if (!newPoint.isSelected) {
              return newPoint;
            }

            if (updatedGeneralFilters.pressure != null) {
              newPoint.isSelected = false;
              if (updatedGeneralFilters.pressure[0] == 0)
                newPoint.isSelected = !newPoint.isPressure;
              if (updatedGeneralFilters.pressure[0] == 1)
                newPoint.isSelected =
                  newPoint.isSelected || newPoint.isPressure;
              if (updatedGeneralFilters.pressure[0] == 2)
                newPoint.isSelected = newPoint.isSelected || newPoint.isBreak;
            }

            if (!newPoint.isSelected) {
              return newPoint;
            }
            if (activeTab.level === "shot") {
              newPoint.shots = newPoint.shots.map((shot) => {
                let newShot = { ...shot };
                newShot.isSelected = true;
                for (const rowFilter of selectedFilters) {
                  let shotMatched = false;
                  for (const optionFilter of rowFilter.options) {
                    if (
                      rowFilter.type === "category" &&
                      (!rowFilter.order ||
                        rowFilter.order.includes(newShot.shotOrderId)) &&
                      optionFilter.value === newShot[rowFilter.propertyName]
                    ) {
                      shotMatched = true;
                      break;
                    } else if (
                      rowFilter.type === "range" &&
                      (!rowFilter.order ||
                        rowFilter.order.includes(newShot.shotOrderId)) &&
                      newShot[rowFilter.propertyName] >=
                      optionFilter.value[0] &&
                      newShot[rowFilter.propertyName] <= optionFilter.value[1]
                    ) {
                      shotMatched = true;
                      break;
                    } else if (rowFilter.type === "advanced") {
                      const shotsFilters = newPoint.shotsFilters.filter(
                        (f) =>
                          ((f.category?.toUpperCase() ===
                            activeMainFilter.name?.toUpperCase() &&
                            f.tab?.toUpperCase() ===
                            activeTab.name?.toUpperCase() &&
                            f.tabRow?.toUpperCase() ===
                            rowFilter.name?.toUpperCase() &&
                            f.filter?.toUpperCase() ===
                            optionFilter.name?.toUpperCase()) ||
                            (f.category?.toUpperCase() ===
                              activeMainFilter.name?.toUpperCase() &&
                              activeMainFilter.name?.toUpperCase() ===
                              activeTab.name?.toUpperCase() &&
                              rowFilter.name?.toUpperCase() === "VARIANTS" &&
                              f.filter?.toUpperCase() ===
                              optionFilter.name?.toUpperCase())) &&
                          (updatedGeneralFilters.player == null ||
                            (updatedGeneralFilters.player[0] !== -1 &&
                              matchesFilter.firstPlayer?.playerId ===
                              f.playerHitId) ||
                            (updatedGeneralFilters.player[0] === -1 &&
                              matchesFilter.firstPlayer?.playerId !==
                              f.playerHitId))
                      );

                      const shotsIds = shotsFilters.flatMap((filter) => {
                        const ids = filter.shotsIds
                          .split(",")
                          .map((id) => parseInt(id.trim()));
                        return ids;
                      });

                      if (shotsIds.includes(newShot.shotId)) {
                        shotMatched = true;
                        break;
                      }
                    }
                  }
                  if (!shotMatched) {
                    newShot.isSelected = false;
                    break;
                  }
                }
                return newShot;
              });
            }

            if (newPoint.shots.some((s) => s.isSelected) && newPoint.isSelected) {
              let shots = newPoint.shots;
              let selectedShots = shots.filter((s) => s.isSelected);
              let selectedShotsIndexes = selectedShots.map((s) => shots.indexOf(s));

              //if point was forcing error and last shot was selected then add 2 to endTime of lastShot
              if (newPoint.rallyEndingShotTypeId === 1) {
                let lastShot = shots[shots.length - 1];
                if (lastShot.isSelected) {
                  lastShot.endTime = lastShot.endTime + 3;
                }
              }

              // for missed first serves, play only the missed serve for max 1.5 sec
              if (newPoint.serveTypeId !== 0) {
                let serveShot = shots.find((s) => s.shotOrderId === 0);
                if (serveShot !== undefined && serveShot.isSelected && serveShot.endTime - serveShot.startTime > 1.5) {
                  serveShot.endTime = serveShot.startTime + 1.5;
                }
              }

              let diff = selectedShotsIndexes.map((s, i) => s - selectedShotsIndexes[i - 1]).slice(1);
              //add value of 1 to diff array at the top
              diff.unshift(1);

              for (let i = 0; i < diff.length; i++) {
                let isLastShot = i === diff.length - 1;
                if (diff[i] === 1 && !isLastShot) {
                  continue;
                }
                // if (diff[i] > 1 && !isLastShot) {
                //   shots[selectedShotsIndexes[i - 1]].endTime = shots[selectedShotsIndexes[i - 1]].endTime + 1;
                //   continue;
                // }

                if (isLastShot) {
                  shots[selectedShotsIndexes[i]].endTime = shots[selectedShotsIndexes[i]].endTime + 1;
                  continue;
                }
              }

              newPoint.shots = shots;
            }

            return newPoint;
          });
          return newMatch;
        });

        // --------FOR DEBUGGING------------------
        //get count of all selected points from all matches with at least one shot selected
        // let selectedPointsCount = 0;
        // newMatchList.forEach((match) => {
        //   match.pointList.forEach((point) => {
        //     for (let i = 0; i < point.shots.length; i++) {
        //       if (point.shots[i].isSelected && point.isSelected) {
        //         selectedPointsCount++;
        //         break;
        //       }
        //     }
        //   });
        // });

        // console.log("selectedPointsCount: ", selectedPointsCount);
        //------------------------------------------
        setIsFiltering(false);
        dispatch(setMatchList(newMatchList));
      }, 0);
    }
  }, [data, selectedFilters, generalFilters]);

  const getUpdateGeneralFilters = () => {
    const updatedGeneralFilters = { ...generalFilters };
    if (
      updatedGeneralFilters.player.length === 0 ||
      updatedGeneralFilters.player.length === 2
    )
      updatedGeneralFilters.player = null;
    if (
      updatedGeneralFilters.pressure.length === 0 ||
      updatedGeneralFilters.pressure.length === 3
    )
      updatedGeneralFilters.pressure = null;
    if (
      updatedGeneralFilters.winner.length === 0 ||
      updatedGeneralFilters.winner.length === 2
    )
      updatedGeneralFilters.winner = null;
    return updatedGeneralFilters;
  };

  useEffect(() => {
    if (!data) return;

    let pointList = Object.values(data).flatMap((pointList) => pointList);
    const updatedGeneralFilters = getUpdateGeneralFilters();

    pointList = pointList.filter((point) => {
      let selectPoint = true;
      if (updatedGeneralFilters.winner != null) {
        const selectedPlayerId = updatedGeneralFilters.player[0];
        if (
          (updatedGeneralFilters.winner[0] == 0 &&
            selectedPlayerId != -1) ||
          (updatedGeneralFilters.winner[0] == 1 && selectedPlayerId == -1)
        )
          selectPoint =
            selectPoint &&
            point.winnerId == matchesFilter.firstPlayer?.playerId;

        if (
          (updatedGeneralFilters.winner[0] == 1 &&
            selectedPlayerId != -1) ||
          (updatedGeneralFilters.winner[0] == 0 && selectedPlayerId == -1)
        )
          selectPoint =
            selectPoint &&
            point.winnerId != matchesFilter.firstPlayer?.playerId;
      }

      if (!selectPoint) return selectPoint;

      if (updatedGeneralFilters.pressure != null) {
        selectPoint = false;
        if (updatedGeneralFilters.pressure[0] == 0)
          selectPoint = !point.isPressure;
        if (updatedGeneralFilters.pressure[0] == 1)
          selectPoint = selectPoint || point.isPressure;
        if (updatedGeneralFilters.pressure[0] == 2)
          selectPoint = selectPoint || point.isBreak;
      }
      return selectPoint;
    });

    var allshotsFilters = pointList.flatMap((point) => point.shotsFilters);

    if (updatedGeneralFilters.player != null) {
      allshotsFilters = allshotsFilters.filter((filter) => {
        const selectedPlayerId = updatedGeneralFilters.player[0];
        return (
          (selectedPlayerId != -1 &&
            matchesFilter.firstPlayer?.playerId === filter.playerHitId) ||
          (selectedPlayerId === -1 &&
            matchesFilter.firstPlayer?.playerId !== filter.playerHitId)
        );
      });
    }

    const uniqueFilters = allshotsFilters?.filter((filter, index, self) =>
      index === self.findIndex((f) => (
        f.category === filter.category &&
        f.filter === filter.filter &&
        f.tab === filter.tab &&
        f.tabRow === filter.tabRow
      ))
    );
    dispatch(setExistingFilters(uniqueFilters));
  }, [data, generalFilters]);

  const selectAllPointsHandler = () => {
    const filteredMatches = getFilteredMatches(matchesPointList, 0, 0);
    const selectedPoints = filteredMatches.flatMap((match) =>
      match.pointList.map((point) => point.pointId)
    );
    dispatch(setSelectedPointsIds(selectedPoints));
  }

  const unselectAllPointsHandler = () => {
    dispatch(setSelectedPointsIds([]));
  }
  let content = null;
  if (isLoading || isFetching || isFiltering) {
    content = (
      <div
        style={{ height: "65vh" }}
        className="card mt-1 mb-2  align-items-center justify-content-center"
      >
        <Spinner />
      </div>
    );
  } else if (error) {
    content = (
      <div
        style={{ height: "65vh" }}
        className="card mt-1 mb-2  align-items-center justify-content-center"
      >
        <h4>
          {" "}
          {status} {error.status} {JSON.stringify(error.error)}
        </h4>
      </div>
    );
  } else if (!matchesPointList || matchesPointList.length == 0) {
    content = (
      <div
        style={{ height: "65vh" }}
        className="card mt-1 mb-2  align-items-center justify-content-center"
      >
        <h4>No Match Is Selected</h4>
      </div>
    );
  } else {
    content = (
      <div className="card-body p-0 scrollbar perfect-scrollbar">
        {matchesPointList.map((match) => (
          <div key={match.matchId}>
            <PointsList match={match} />
          </div>
        ))}
      </div>
    );
  }

  return (
    <div style={{ height: "65vh" }} className="card mt-1 mb-2 ">
      {isCustomClipModalOpen && (
        <CustomClipModal onCloseModal={setIsCustomClipModalOpen} />
      )}
      <div className="card-header pl-2">
        <h5 className="mb-3">Points ({filteredMatches ? filteredMatches.reduce((sum, match) => sum + match.pointList.length, 0) : 0})</h5>
        <div className="flex-column d-flex">
          <div className="d-flex row flex-nowrap justify-content-between align-items-center mb-2 ml-0">
            <button
              className="btn btn-outline-warning btn-sm"
              type="button"
              data-toggle="modal"
              data-target="#customClipModal"
              disabled={filteredMatches?.length === 0}
              onClick={() => setIsCustomClipModalOpen(true)}
            >
              <strong>DOWNLOAD VIDEO</strong>
            </button>
            <button
              className="btn btn-falcon-default btn-sm"
              onClick={() => dispatch(setNavigateToPoint(true))}
            >
              Go To Active Point
            </button>
          </div>
          {matchesPointList?.length > 0 &&
            <div className="d-flex row mt-2 align-items-center ml-0">
              <button className={`btn btn-sm btn-light ${classes["point-select"]} mr-2`} onClick={selectAllPointsHandler} >Select All</button>
              <button className={`btn btn-sm btn-light ${classes["point-select"]} mr-2`} onClick={unselectAllPointsHandler} >Unselect All ({selectedPointsIds ? selectedPointsIds.length : 0})</button>
            </div>
          }
        </div>
      </div>
      {content}
    </div>
  );
};

export default PointSelection;
