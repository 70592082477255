import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useOktaAuth } from "@okta/okta-react";

import { selectBgReportInputs, setBgReportInputs } from "../store/reportGenerationSlice";
import { useGetAllBGPlayersQuery, useGetAllBGTournamentsQuery } from "../../api/completeMatchesSlice";
import { useGetBgReportMatchesCountMutation, useCreateBgReportMutation } from "../../api/reportsSlice";
import { yearsOptions, surfaceOptions, colourStyles } from "../constants/reportGenerationOptions";
import BGTrackingSheet from "./BGTrackingSheet";
import Spinner from "../../../UI/Spinner";
import Select from "react-select";

const NewBGReport = () => {
    const dispatch = useDispatch();
    const { authState } = useOktaAuth();
    const [tournamentOptions, setTournamentOptions] = useState([]);
    const [clientPlayersOptions, setClientPlayersOptions] = useState([]);
    const [opponentPlayersOptions, setOpponentPlayersOptions] = useState([]);
    const [selectedSurfaces, setSelectedSurfaces] = useState([surfaceOptions[0], surfaceOptions[1], surfaceOptions[2]]);
    const [trackingSheet, setTrackingSheet] = useState(null);

    const formInputs = useSelector(selectBgReportInputs);

    const { data: allPlayers, isLoading: loadingBGPlayers } = useGetAllBGPlayersQuery();

    const { data: tournaments } = useGetAllBGTournamentsQuery();
    const [createBgReport, { isLoading: isCreatingBg, isSuccess: createdSuccessfully, isError: creationError }] = useCreateBgReportMutation();
    const [getBgReportMatchesCount, { isLoading: loadingReportMatchesCount, isError: reportMatchesCountError }] = useGetBgReportMatchesCountMutation();

    const invalidInputs = formInputs.selectedTargetPlayers.length === 0 || selectedSurfaces.length === 0;

    useEffect(() => {
        setTrackingSheet(null);
    }, [formInputs, selectedSurfaces])

    useEffect(() => {
        if (tournaments) {
            setTournamentOptions(tournaments.map((tournament) => ({
                label: tournament,
                value: tournament
            })));
        }
    }, [tournaments]);


    useEffect(() => {
        if (allPlayers) {
            setClientPlayersOptions(allPlayers
                .map((player) => ({
                    label: player,
                    value: player
                })));
            setOpponentPlayersOptions([{ label: "All Players", value: "all" }, ...allPlayers
                .map((player) => ({
                    label: player,
                    value: player
                }))]);

            if (allPlayers.length > 0)
                dispatch(setBgReportInputs({ ...formInputs, selectedTargetPlayers: [allPlayers[0]] }));
        }
    }, [allPlayers])

    useEffect(() => {
        toastr.options.positionClass = "toast-bottom-right";
        if (reportMatchesCountError)
            toastr.error("Failed to get matches count!", "Matches Count");

    }, [reportMatchesCountError]);

    useEffect(() => {
        toastr.options.positionClass = "toast-bottom-right";
        if (createdSuccessfully) {
            toastr.success("Created Successfully!", "Create New Report");
            $('#AddNewBgReportModal').modal('hide');
        }
        else if (creationError)
            toastr.error("Failed to create!", "Create New Report");
    }, [createdSuccessfully, creationError]);

    const targetPlayerChangedHandler = (selectedOption) => {
        var player = allPlayers.find(p => p.name === selectedOption.value);
        dispatch(setBgReportInputs({ ...formInputs, selectedTargetPlayers: [selectedOption.value] }));

        if (formInputs.selectedOpponents.length > 0)
            dispatch(setBgReportInputs({ ...formInputs, selectedOpponents: [] }));
    };

    const createReportHandler = async () => {
        await createBgReport(getReportInputsPayload());
    }

    const matchesCountHandler = async () => {
        var response = await getBgReportMatchesCount(getReportInputsPayload());
        if (response)
            setTrackingSheet(response.data);
    }

    const getReportInputsPayload = () => {
        return {
            ...formInputs,
            blobOutputDir: `user-reports/${authState.idToken.claims.preferred_username}`,
            isHardSelected: selectedSurfaces.some(s => s.value === 0),
            isClaySelected: selectedSurfaces.some(s => s.value === 1),
            isGrassSelected: selectedSurfaces.some(s => s.value === 2),
            isCarpetSelected: selectedSurfaces.some(s => s.value === 3),
            matchesCount: trackingSheet?.length
        }
    }

    return (
        <div
            className="modal fade"
            id="AddNewBgReportModal"
            role="dialog"
            aria-labelledby="AddNewBgReportModalLabel"
            aria-hidden="true"
        >
            <div style={{ minWidth: "70%", maxWidth: "70%" }} className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h3 className="modal-title" id="AddNewBgReportModalLabel">
                            Create New BG Report
                        </h3>
                    </div>
                    <div style={{ height: "75vh" }} className="modal-body scrollbar perfect-scrollbar">
                        <div style={{ width: "100%" }} className="container form-group d-flex row flex-wrap">
                            <div style={{ width: "20%", minWidth: "10rem" }} className="d-flex flex-column mr-4 mb-2">
                                <label >Target Player</label>
                                {loadingBGPlayers ? <Spinner /> : <Select
                                    className="dark-theme"
                                    value={formInputs.selectedTargetPlayers.length > 0 ? clientPlayersOptions.find(p => p.value === formInputs.selectedTargetPlayers[0]) : null}
                                    styles={colourStyles}
                                    options={clientPlayersOptions}
                                    onChange={targetPlayerChangedHandler} />}
                            </div>
                            <div style={{ width: "20%", minWidth: "10rem" }} className="d-flex flex-column mr-4 mb-2 text-nowrap">
                                <label >Opponent Player</label>
                                {loadingBGPlayers ? <Spinner /> : <Select
                                    className="dark-theme"
                                    value={formInputs.selectedOpponents.length > 0 ? opponentPlayersOptions.find(p => p.value === formInputs.selectedOpponents[0]) : opponentPlayersOptions[0]}
                                    options={opponentPlayersOptions}
                                    styles={colourStyles}

                                    onChange={(selectedOption) => dispatch(setBgReportInputs({ ...formInputs, selectedOpponents: selectedOption.value === "all" ? [] : [selectedOption.value] }))} />}
                            </div>

                            <div style={{ width: "10%", minWidth: "6rem" }} className="d-flex flex-column mr-4 mb-2">
                                <label htmlFor="fromYear">From</label>
                                <select
                                    className="form-control mb-3"
                                    id="fromYear"
                                    value={formInputs.fromYear}
                                    onChange={(e) => dispatch(setBgReportInputs({ ...formInputs, fromYear: parseInt(e.target.value) }))}
                                >
                                    {yearsOptions.map((option) => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div style={{ width: "10%", minWidth: "6rem" }} className="d-flex flex-column mr-4 mb-2">
                                <label htmlFor="toYear">To</label>
                                <select
                                    className="form-control mb-3"
                                    id="toYear"
                                    value={formInputs.toYear}
                                    onChange={(e) => dispatch(setBgReportInputs({ ...formInputs, toYear: parseInt(e.target.value) }))}>
                                    {yearsOptions.map((option) => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div style={{ minWidth: "15rem", maxWidth: "25%", width: "fit-content" }} className="d-flex flex-column mr-4">
                                <label htmlFor="surface">Surface</label>
                                <Select
                                    closeMenuOnSelect={false}
                                    isMulti
                                    options={surfaceOptions}
                                    styles={colourStyles}
                                    onChange={(selectedOptions) => setSelectedSurfaces(selectedOptions)}
                                    placeholder="Select"
                                    value={selectedSurfaces}
                                />
                            </div>
                            <div style={{ minWidth: "20rem", maxWidth: "30%", width: "fit-content" }} className="d-flex flex-column mr-4 mb-2">
                                <label htmlFor="tournaments">Tournaments</label>
                                <Select
                                    className="dark-theme"
                                    closeMenuOnSelect={false}
                                    isMulti
                                    styles={colourStyles}
                                    options={tournamentOptions}
                                    value={formInputs.selectedTournaments.length > 0 ? tournamentOptions.filter(t => formInputs.selectedTournaments.includes(t.value)) : []}
                                    onChange={(selectedOptions) => dispatch(setBgReportInputs({ ...formInputs, selectedTournaments: selectedOptions.map((option) => option.value) }))} />
                            </div>
                            <button style={{ width: "10%", minWidth: "fit-content", height: "2.2rem" }} className="btn btn-primary btn-md mt-4 mb-2 text-nowrap" type="button" disabled={loadingReportMatchesCount || invalidInputs} onClick={matchesCountHandler}> {loadingReportMatchesCount ? "Loading" : "Matches Count"}</button>
                        </div>
                        {trackingSheet && <BGTrackingSheet trackingSheet={trackingSheet} targetPlayer={formInputs.selectedTargetPlayers[0]} />}
                    </div>
                    <div className="modal-footer">
                        <button className="btn btn-secondary btn-md" type="button" data-dismiss="modal">
                            Cancel
                        </button>

                        <button className="btn btn-warning btn-md" type="button"
                            onClick={createReportHandler}
                            disabled={isCreatingBg || invalidInputs || (trackingSheet?.length == 0)}>
                            {isCreatingBg ? "Creating ..." : "Create"}
                        </button>
                    </div>
                </div>
            </div >
        </div >
    );
}
export default NewBGReport;