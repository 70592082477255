import {
  useGetCustomClipsQuery,
  useDeleteCustomClipMutation,
} from "../api/completeMatchesSlice";
import DataTable from "react-data-table-component";
import Spinner from "../../UI/Spinner";
import { useState, useEffect } from "react";
import { selectIsVideoClient, selectUser, selectIsAllowedShotsPlayer } from "../auth/authSlice";
import { useSelector } from "react-redux";
import { formatShortDetailedUtcDate, customReactDataTableStyles } from "../../utils/app-utils";
import DownloadButton from "../../UI/buttons/DownloadButton";
import DeleteButton from "../../UI/buttons/DeleteButton";
import ConfirmationModal from "../../components/ConfirmationModal";
import NotAuthorizedPage from "../../pages/notAuthorizedPage";

const CustomClipsPage = () => {
  const user = useSelector(selectUser);
  const isVideoClient = useSelector(selectIsVideoClient);
  const isAllowedShotsPlayer = useSelector(selectIsAllowedShotsPlayer);

  const { data, isLoading, error } = useGetCustomClipsQuery(null, { pollingInterval: 30000 });
  const [customClips, setCustomClips] = useState(data);
  const [deleteClipName, setDeleteClipName] = useState(null);

  const [deleteCustomClip, { isSuccess: deletedSuccessfully, isError: deleteError },] = useDeleteCustomClipMutation();

  useEffect(() => {
    toastr.options.positionClass = "toast-bottom-right";
    if (deletedSuccessfully)
      toastr.success("Deleted Successfully!", "Delete Custom Clip");
    else if (deleteError)
      toastr.error("Failed to delete!", "Delete Custom Clip");
  }, [deletedSuccessfully, deleteError]);

  useEffect(() => {
    if (data) setCustomClips(data);
  }, [data]);

  const columns = [
    {
      name: 'ID',
      selector: clip => clip.id,
      omit: true,
    },
    {
      name: 'Clip Name',
      selector: clip => clip.name,
    },
    {
      name: 'Actions',
      selector: clip => getActions(clip),
    },
    {
      name: 'Status',
      selector: clip => getStatus(clip),
    },
    {
      name: '# Matches',
      selector: clip => clip.matchesCount,
      sortable: true,
    },
    {
      name: '# Points',
      selector: clip => clip.pointsCount,
      sortable: true,
    },
    {
      name: '# Shots',
      selector: clip => clip.shotsCount == 0 ? "-" : clip.shotsCount,
      sortable: true,
    },
    {
      name: 'Created At',
      selector: clip => formatShortDetailedUtcDate(clip.createdAt),
      sortable: true,
      wrap: true,
    }
  ];

  const getActions = (clip) => {
    return <>
      <DownloadButton
        classes="mr-1"
        disabled={!(clip.status === "Finished" && clip.result === "Success")}
        onClick={() => downloadCustomClipHandler(clip.name)} />

      <DeleteButton
        classes="mr-2"
        onClick={() => {
          setDeleteClipName(clip.name);
          $("#deleteClipConfirmationModal").modal("show");
        }} />
    </>
  }

  const getStatus = (clip) => {
    if (clip.status === "In Progress") {
      return <div
        className="spinner-border spinner-border-sm text-secondary"
        role="status"
        data-toggle="tooltip"
        title="In Progress"
      />
    }
    else if (clip.status === "Created") {
      return <span
        className="d-inline-block"
        tabIndex="1"
        data-toggle="tooltip"
        title="Queued"
      >
        <i className="fas fa-clipboard-list text-secondary"></i>
      </span>

    }
    else if (clip.status === "Finished" && clip.result == "Failed") {
      return <span
        className="d-inline-block"
        tabIndex="1"
        data-toggle="tooltip"
        title="Failed"
      >
        <i className="fas fa-times-circle text-danger"></i>
      </span>
    } else if (clip.status === "Finished" && clip.result == "Success") {
      return <span
        className="d-inline-block"
        tabIndex="1"
        data-toggle="tooltip"
        title="Succeeded"
      >
        <i className="fas fa-check-circle text-success"></i>
      </span>
    }
  }

  const downloadCustomClipHandler = (clipName) => {
    let url = `${process.env.REACT_APP_API_URL}/videos/custom/download?userName=${user.preferred_username}&clipName=${encodeURIComponent(clipName)}`;
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const deleteCustomClipHandler = (clipName) => {
    $("#deleteClipConfirmationModal").modal("hide");
    setCustomClips((prev) => prev.filter((clip) => clip.name !== clipName));
    deleteCustomClip(clipName);
  };

  if (!user) return <Spinner />;
  else if (!isVideoClient || !isAllowedShotsPlayer) return <NotAuthorizedPage />;

  let content = null;
  if (isLoading) {
    content = <Spinner />;
  } else if (error) {
    content = <p>{error}</p>;
  } else if (customClips) {
    content = (
      <>
        <ConfirmationModal
          id="deleteClipConfirmationModal"
          title=" Delete Custom Clip"
          body={`Are you sure you want to delete ${deleteClipName}.mp4?`}
          onConfirm={() => deleteCustomClipHandler(deleteClipName)}
        />
        <DataTable
          columns={columns}
          data={customClips}
          responsive
          pagination
          highlightOnHover
          theme="dark"
          customStyles={customReactDataTableStyles}
          noDataComponent="No custom clips available"
        />
      </>
    );
  }

  return (
    <div>
      <h2 className="mb-5">Custom Clips</h2>
      {content}
    </div>
  );
};

export default CustomClipsPage;
