import { useDispatch, useSelector } from "react-redux";
import {
  selectPrevShotsCount,
  selectNextShotsCount,
  setShotsCount,
} from "../store/videoPlayerSlice";

const prevShotsCountOptions = [0, 1, 2, 3];
const nextShotsCountOptions = [0, 1, 2, 3, 1000];

const ShotsCount = () => {
  const dispatch = useDispatch();
  const prevShotsCount = useSelector(selectPrevShotsCount);
  const nextShotsCount = useSelector(selectNextShotsCount);

  const description = <div>Play video with <strong>{prevShotsCount}</strong> shot(s) before and <strong>{nextShotsCount == 1000 ? "all" : nextShotsCount}</strong> shot(s) after selected shot.</div>;

  const handlePrevShotsCountChange = (newValue) => {
    dispatch(
      setShotsCount({
        prevShotsCount: newValue,
        nextShotsCount,
      })
    );
  };

  const handleNextShotsCountChange = (newValue) => {
    dispatch(
      setShotsCount({
        prevShotsCount,
        nextShotsCount: newValue,
      })
    );
  };

  return (
    <div style={{ height: "fit-content" }} className="card ">
      <div style={{ paddingBottom: "0.5rem" }} className="card-header">
        <span style={{ fontSize: "18px", fontWeight: "bold" }}>
          For selected shot play:
        </span>
      </div>
      <div style={{ height: "80%" }} className="row flex-nowrap justify-content-center align-items-center">
        <div style={{ padding: "0", width: "40%" }} className="btn-group mr-2">
          <button
            className="btn dropdown-toggle mb-2 btn-light"
            type="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Before&nbsp;({prevShotsCount})&nbsp;&nbsp;
          </button>
          <div className="dropdown-menu">
            {prevShotsCountOptions.map((n) => (
              <span
                key={`before-${n}`}
                className="dropdown-item text-center fs-0"
                onClick={() => handlePrevShotsCountChange(n)}
              >
                {n}
              </span>
            ))}
          </div>
        </div>
        <div style={{ padding: "0", width: "40%" }} className="btn-group">
          <button
            className="btn dropdown-toggle mb-2 btn-light"
            type="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            After&nbsp;({nextShotsCount == 1000 ? "all" : nextShotsCount})&nbsp;&nbsp;
          </button>
          <div className="dropdown-menu">
            {nextShotsCountOptions.map((n) => (
              <span
                key={`next-${n}`}
                className="dropdown-item text-center fs-0"
                onClick={() => handleNextShotsCountChange(n)}
              >
                {n === 1000 ? "To end of point" : n}
              </span>
            ))}
          </div>
        </div>
      </div>
      <div style={{ paddingTop: "0.5rem" }} className="card-footer">
        <span style={{ fontSize: "14px" }}>{description}</span>
      </div>
    </div>
  );
};

export default ShotsCount;
