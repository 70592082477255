import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import getAuthToken from "../auth/authManager";

export const postMatchSlice = createApi({
  reducerPath: "postMatchListApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    mode: "cors",
    prepareHeaders: (headers) => {
      const token = getAuthToken();
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getPostMatchList: builder.query({
      query: () => `/PostMatchReport`,
    }),
  }),
});

export const { useGetPostMatchListQuery } = postMatchSlice;
