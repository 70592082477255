
import { useState, useEffect } from "react";
import ReportIssue from "./ReportIssue";
import DataTable from "react-data-table-component";
import { formatShortDetailedUtcDate, customReactDataTableStyles } from "../../utils/app-utils";
import DownloadButton from "../../UI/buttons/DownloadButton";
import DeleteButton from "../../UI/buttons/DeleteButton";
import ConfirmationModal from "../../components/ConfirmationModal";
import { useDeleteReportMutation } from "../api/reportsSlice";
import { opponentTypeOptions, getCurrentProgress } from "./constants/reportGenerationOptions";

const ReportsTable = ({ reports, reportType }) => {

    const [reportId, setReportId] = useState(null);
    const [deleteReportId, setDeleteReportId] = useState(null);
    const [deleteReport, { isLoading: isDeleting, isSuccess: deletedSuccessfully, isError: deleteFailed }] = useDeleteReportMutation();

    useEffect(() => {
        toastr.options.positionClass = "toast-bottom-right";
        if (deletedSuccessfully) {
            toastr.success("Report Deleted successfully!", "Delete Report");
        }
        if (deleteFailed) {
            toastr.error("Failed to Delete Report!", "Delete Report");
        }
    }, [deletedSuccessfully, deleteFailed]);

    const columns = [
        {
            name: 'ID',
            selector: report => report.clientReportId,
            omit: true,
        },
        {
            name: 'Target',
            selector: report => <strong className="fs-0">{report.reportInputs.targetPlayer}</strong>,
            sortable: true,
            wrap: true,
        },
        {
            name: 'Opponent',
            selector: report => getOpponent(report),
            sortable: true,
            wrap: true,
        },
        {
            name: 'Years',
            selector: report => `${report.reportInputs.fromYear}-${report.reportInputs.toYear}`,
            sortable: true,
            wrap: true,
        },
        {
            name: 'Tournaments',
            selector: report => getTournaments(report),
            sortable: true,
            wrap: true,
        },
        {
            name: 'Surfaces',
            selector: report => report.reportInputs.targetSurfaces.split(",").map(surface => getSurfaceBadge(report.clientReportId, surface)),
            sortable: true,
            wrap: true,
        },
        {
            name: '# Matches',
            selector: report => report.reportInputs.matchesCount,
            sortable: true,
            wrap: true,
        },
        {
            name: 'Created At',
            selector: report => formatShortDetailedUtcDate(report.createdAt),
            sortable: true,
            wrap: true,
        },
        {
            name: 'Status',
            selector: report => `${reportType === "std" ? `${getCurrentProgress(report.reportStatus)} ` : ""}${report.reportStatus}`,
            sortable: true,
            wrap: true,
        },
        {
            name: 'Actions',
            selector: report => getActions(report),
        },
        {
            name: 'Report Issue',
            selector: report => getReportAction(report),
        },
    ];

    const getOpponent = (report) => {
        if (reportType == "std") {
            return report.reportInputs.opponentName !== "" ? report.reportInputs.opponentName : opponentTypeOptions.find(option => option.value === report.reportInputs.opponent).label;
        }
        else {
            return report.reportInputs.opponentPlayer == "" ? "All" : report.reportInputs.opponentPlayer;
        }
    }

    const getTournaments = (report) => {
        if (reportType == "std") {
            return report.reportInputs.selectedTournaments.length > 0 ? report.reportInputs.selectedTournaments.join(", ") : "All";
        } else {
            return report.reportInputs.tournaments == "" ? "All" : report.reportInputs.tournaments;
        }
    }

    const getActions = (report) => {
        return <>
            <DownloadButton
                classes="mr-1"
                disabled={report.reportStatus !== "Succeeded"}
                onClick={() => downloadHandler(report.blobPath)} />
            <DeleteButton
                classes="mr-2"
                onClick={() => { setDeleteReportId(report.clientReportId); $(`#${reportType}DeleteReportConfirmationModal`).modal('show'); }} />
        </>
    }

    const getReportAction = (report) => {
        if (report.reportStatus == "Failed" && !report.isReported) {
            return (
                <button
                    className="btn btn-danger fs--1"
                    type="button"
                    onClick={() => {
                        setReportId(report.clientReportId); $(`#${reportType}ReportIssueConfirmationModal`).modal('show')
                    }
                    }>
                    Report Issue
                </button >
            );
        }
        else if (report.isReported) {
            return "Issue Reported";
        }
        else {
            return "-"
        }
    }

    const getSurfaceBadge = (clientReportId, surface) => {
        const classes = {
            "Hard": "badge-primary",
            "Clay": "badge-danger",
            "Grass": "badge-success",
            "Carpet": "badge-secondary",
            "All": "badge-info"
        };
        return <span key={`${clientReportId}-${surface}`} className={`badge badge-sm rounded-capsule mr-1 my-1 fs--2 ${classes[surface]}`}>{surface}</span>
    }

    const downloadHandler = (blobPath) => {
        const downloadLink = `${process.env.REACT_APP_API_URL}/reportExecutionRecord/download?blobPath=${blobPath}`;
        window.open(downloadLink, "_blank", "noopener,noreferrer");
    };

    const deleteHandler = async (reportId) => {
        await deleteReport(reportId);
        $(`#${reportType}DeleteReportConfirmationModal`).modal('hide');
    }


    return (
        <>
            <ConfirmationModal
                id={`${reportType}DeleteReportConfirmationModal`}
                title=" Delete Standard Report"
                body={`Are you sure you want to delete this report?`}
                onConfirm={() => deleteHandler(deleteReportId)}
                disabled={isDeleting}
            />
            <ReportIssue id={`${reportType}ReportIssueConfirmationModal`} reportId={reportId} />
            <DataTable
                columns={columns}
                data={reports}
                responsive
                pagination
                highlightOnHover
                theme="dark"
                customStyles={customReactDataTableStyles}
                noDataComponent="No reports available"
            />
        </>
    );
};


export default ReportsTable;