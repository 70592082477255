
import NewBGReport from "./NewBGReport";
import ReportsTable from "../ReportsTable";

const BGReportGeneration = ({ reports }) => {
    return (
        <>
            <button
                className="btn btn-primary fs--1 mb-4"
                type="button"
                data-toggle="modal"
                data-target="#AddNewBgReportModal">
                Create
                <i className="fas fa-plus-circle ml-2"></i>
            </button>
            <NewBGReport />
            <ReportsTable reports={reports} reportType="bg" />
        </>
    );
}
export default BGReportGeneration;