import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import getAuthToken from "../auth/authManager";

export const reportsSlice = createApi({
    reducerPath: "reportapi",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL,
        mode: "cors",
        prepareHeaders: (headers) => {
            const token = getAuthToken();
            if (token) {
                headers.set("authorization", `Bearer ${token}`);
            }
            return headers;
        },
    }),
    tagTypes: ["clientReports"],
    endpoints: (builder) => ({
        getClientReports: builder.query({
            query: () => `/report/client`,
            providesTags: ["clientReports"]
        }),
        getStdReportMatchesCount: builder.mutation({
            query: (payload) => ({
                url: `/report/std/matches`,
                method: "POST",
                body: payload,
            }),
        }),
        getBgReportMatchesCount: builder.mutation({
            query: (payload) => ({
                url: `/report/bg/matches`,
                method: "POST",
                body: payload,
            }),
        }),
        createStandardReport: builder.mutation({
            query: (reportInputsModel) => ({
                url: `/report/client/create/std`,
                method: "POST",
                body: reportInputsModel,
            }),
            invalidatesTags: ["clientReports"],
        }),
        createBgReport: builder.mutation({
            query: (reportInputsModel) => ({
                url: `/report/client/create/bg`,
                method: "POST",
                body: reportInputsModel,
            }),
            invalidatesTags: ["clientReports"],
        }),
        reportIssue: builder.mutation({
            query: ({ clientReportId, comment }) => ({
                url: `/report/client/issue/${clientReportId}`,
                method: "POST",
                body: JSON.stringify(comment),
                headers: {
                    "Content-Type": "application/json",
                },
            }),
            invalidatesTags: ["clientReports"],
        }),
        deleteReport: builder.mutation({
            query: (clientReportId) => ({
                url: `/report/client/${clientReportId}`,
                method: "DELETE",
            }),
            invalidatesTags: ["clientReports"],
        }),
    }),
});

export const {
    useGetClientReportsQuery,
    useGetStdReportMatchesCountMutation,
    useGetBgReportMatchesCountMutation,
    useCreateStandardReportMutation,
    useCreateBgReportMutation,
    useReportIssueMutation,
    useDeleteReportMutation,
} = reportsSlice;
export default reportsSlice.reducer;
