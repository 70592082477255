import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useOktaAuth } from "@okta/okta-react";

import { selectStdReportInputs, setStdReportInputs } from "../store/reportGenerationSlice";
import { useGetTargetEventInstancesMutation, useGetUserPlayersQuery, useGetAllLocalPlayersQuery } from "../../api/completeMatchesSlice";
import { useGetStdReportMatchesCountMutation, useCreateStandardReportMutation } from "../../api/reportsSlice";
import { opponentTypeOptions, backhandTypeOptions, yearsOptions, surfaceOptions, colourStyles } from "../constants/reportGenerationOptions";
import TrackingSheet from "./TrackingSheet";
import Spinner from "../../../UI/Spinner";
import Select from "react-select";

const NewStdReport = () => {
    const dispatch = useDispatch();
    const { authState } = useOktaAuth();
    const [tournamentOptions, setTournamentOptions] = useState([]);
    const [clientPlayersOptions, setClientPlayersOptions] = useState([]);
    const [opponentPlayersOptions, setOpponentPlayersOptions] = useState([]);
    const [selectedSurfaces, setSelectedSurfaces] = useState([surfaceOptions[0], surfaceOptions[1], surfaceOptions[2]]);
    const [trackingSheet, setTrackingSheet] = useState(null);

    const formInputs = useSelector(selectStdReportInputs);


    const { data: clientPlayers, isLoading: loadingClientPlayers } = useGetUserPlayersQuery();
    const { data: allPlayers, isLoading: loadingLocalPlayers } = useGetAllLocalPlayersQuery();

    const [getTargetTournaments] = useGetTargetEventInstancesMutation();
    const [createStandardRecord, { isLoading: isCreatingStd, isSuccess: createdSuccessfully, isError: creationError }] = useCreateStandardReportMutation();
    const [getStdReportMatchesCount, { isLoading: loadingReportMatchesCount, isError: reportMatchesCountError }] = useGetStdReportMatchesCountMutation();

    const invalidInputs = formInputs.selectedTargetPlayers.length === 0 || (formInputs.opponent === 3 && formInputs.selectedOpponentPlayers.length === 0) || selectedSurfaces.length === 0;

    useEffect(() => {
        setTrackingSheet(null);
    }, [formInputs, selectedSurfaces])

    useEffect(() => {
        const fetchData = async () => {
            if (formInputs.selectedTargetPlayers.length > 0) {
                var response = await getTargetTournaments({
                    targetLocalName: formInputs.selectedTargetPlayers[0],
                    fromYear: formInputs.fromYear,
                    toYear: formInputs.toYear,
                    surfaces: selectedSurfaces.map(s => s.label)
                });
                if (response.data) {
                    setTournamentOptions(response.data.map((tournament) => ({
                        label: tournament.fullName,
                        value: tournament.name
                    })));
                }
            }
        };
        fetchData();
    }, [formInputs.selectedTargetPlayers, formInputs.fromYear, formInputs.toYear, selectedSurfaces]);


    useEffect(() => {
        if (clientPlayers) {
            setClientPlayersOptions(clientPlayers
                .map((player) => ({
                    label: player.name,
                    value: player.localName
                })));

            if (clientPlayers.length > 0)
                dispatch(setStdReportInputs({ ...formInputs, selectedTargetPlayers: [clientPlayers[0].localName], category: clientPlayers[0].category }));
        }
    }, [clientPlayers])

    useEffect(() => {
        if (allPlayers) {
            setOpponentPlayersOptions(allPlayers
                .filter(p => p.category === formInputs.category)
                .map((player) => ({
                    label: player.name,
                    value: player.localName
                })));
        }
    }, [allPlayers, formInputs.category])

    useEffect(() => {
        toastr.options.positionClass = "toast-bottom-right";
        if (reportMatchesCountError)
            toastr.error("Failed to get matches count!", "Matches Count");

    }, [reportMatchesCountError]);

    useEffect(() => {
        toastr.options.positionClass = "toast-bottom-right";
        if (createdSuccessfully) {
            toastr.success("Created Successfully!", "Create New Report");
            $('#AddNewStandardReportModal').modal('hide');
        }
        else if (creationError)
            toastr.error("Failed to create!", "Create New Report");
    }, [createdSuccessfully, creationError]);

    const targetPlayerChangedHandler = (selectedOption) => {
        var player = clientPlayers.find(p => p.localName === selectedOption.value);
        dispatch(setStdReportInputs({ ...formInputs, selectedTargetPlayers: [selectedOption.value], category: player.category }));

        if (formInputs.selectedOpponentPlayers.length > 0 && player.category !== allPlayers.find(p => p.localName === formInputs.selectedOpponentPlayers[0]).category)
            dispatch(setStdReportInputs({ ...formInputs, selectedOpponentPlayers: [] }));
    };

    const opponentChangedHandler = (e) => {
        const opponentType = parseInt(e.target.value);
        const newFormInputs = { ...formInputs, opponent: opponentType, selectedOpponentPlayers: [] };
        if (opponentType === 3)
            newFormInputs.backhandType = backhandTypeOptions[0].value;
        dispatch(setStdReportInputs(newFormInputs));
    };

    const createReportHandler = async () => {
        await createStandardRecord(getReportInputsPayload());
    }

    const matchesCountHandler = async () => {
        var response = await getStdReportMatchesCount(getReportInputsPayload());
        if (response)
            setTrackingSheet(response.data);
    }

    const getReportInputsPayload = () => {
        return {
            ...formInputs,
            blobOutputDir: `user-reports/${authState.idToken.claims.preferred_username}`,
            isHardSelected: selectedSurfaces.some(s => s.value === 0),
            isClaySelected: selectedSurfaces.some(s => s.value === 1),
            isGrassSelected: selectedSurfaces.some(s => s.value === 2),
            isCarpetSelected: selectedSurfaces.some(s => s.value === 3),
            matchesCount: trackingSheet?.matches.length
        }
    }

    return (
        <div
            className="modal fade"
            id="AddNewStandardReportModal"
            role="dialog"
            aria-labelledby="AddNewStandardReportModalLabel"
            aria-hidden="true"
        >
            <div style={{ minWidth: "70%", maxWidth: "70%" }} className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h3 className="modal-title" id="AddNewStandardReportModalLabel">
                            Create New Standard Report
                        </h3>
                    </div>
                    <div style={{ height: "75vh" }} className="modal-body scrollbar perfect-scrollbar">
                        <div style={{ width: "100%" }} className="container form-group d-flex row flex-wrap">
                            <div style={{ width: "20%", minWidth: "10rem" }} className="d-flex flex-column mr-4 mb-2">
                                <label >Target Player</label>
                                {loadingClientPlayers ? <Spinner /> : <Select
                                    className="dark-theme"
                                    value={formInputs.selectedTargetPlayers.length > 0 ? clientPlayersOptions.find(p => p.value === formInputs.selectedTargetPlayers[0]) : null}
                                    styles={colourStyles}
                                    options={clientPlayersOptions}
                                    onChange={targetPlayerChangedHandler} />}
                            </div>
                            <div style={{ width: "10%", minWidth: "10rem" }} className="d-flex flex-column mr-4 mb-2">
                                <label htmlFor="opponent">Opponent</label>
                                <select
                                    className="form-control mb-3"
                                    id="opponent"
                                    value={formInputs.opponent}
                                    onChange={opponentChangedHandler}>
                                    {opponentTypeOptions.map((option) => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            {formInputs.opponent != 3 && <div style={{ width: "10%", minWidth: "10rem" }} className="d-flex flex-column mr-4 mb-2 text-nowrap">
                                <label htmlFor="backhandType">Backhand Type</label>
                                <select
                                    className="form-control mb-3"
                                    id="backhandType"
                                    value={formInputs.backhandType}
                                    onChange={(e) => dispatch(setStdReportInputs({ ...formInputs, backhandType: parseInt(e.target.value) }))}>
                                    {backhandTypeOptions.map((option) => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            </div>}
                            {formInputs.opponent === 3 && <div style={{ width: "20%", minWidth: "10rem" }} className="d-flex flex-column mr-4 mb-2 text-nowrap">
                                <label >Opponent Player</label>
                                {loadingLocalPlayers ? <Spinner /> : <Select
                                    className="dark-theme"
                                    value={formInputs.selectedOpponentPlayers.length > 0 ? opponentPlayersOptions.find(p => p.value === formInputs.selectedOpponentPlayers[0]) : null}
                                    options={opponentPlayersOptions}
                                    styles={colourStyles}

                                    onChange={(selectedOption) => dispatch(setStdReportInputs({ ...formInputs, selectedOpponentPlayers: [selectedOption.value] }))} />}
                            </div>}

                            <div style={{ width: "10%", minWidth: "6rem" }} className="d-flex flex-column mr-4 mb-2">
                                <label htmlFor="fromYear">From</label>
                                <select
                                    className="form-control mb-3"
                                    id="fromYear"
                                    value={formInputs.fromYear}
                                    onChange={(e) => dispatch(setStdReportInputs({ ...formInputs, fromYear: parseInt(e.target.value) }))}
                                >
                                    {yearsOptions.map((option) => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div style={{ width: "10%", minWidth: "6rem" }} className="d-flex flex-column mr-4 mb-2">
                                <label htmlFor="toYear">To</label>
                                <select
                                    className="form-control mb-3"
                                    id="toYear"
                                    value={formInputs.toYear}
                                    onChange={(e) => dispatch(setStdReportInputs({ ...formInputs, toYear: parseInt(e.target.value) }))}>
                                    {yearsOptions.map((option) => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div style={{ width: "10%", minWidth: "6rem" }} className="d-flex flex-column mr-4 mb-2 text-nowrap">
                                <label htmlFor="targetSpeedUnit">Speed Unit</label>
                                <select className="form-control" id="targetSpeedUnit"
                                    value={formInputs.targetSpeedUnit}
                                    onChange={(e) => dispatch(setStdReportInputs({ ...formInputs, targetSpeedUnit: e.target.value }))}>
                                    <option value="MPH">MPH</option>
                                    <option value="KMH">KMH</option>
                                </select>
                            </div>
                            <div style={{ minWidth: "15rem", maxWidth: "25%", width: "fit-content" }} className="d-flex flex-column mr-4">
                                <label htmlFor="surface">Surface</label>
                                <Select
                                    closeMenuOnSelect={false}
                                    isMulti
                                    options={surfaceOptions}
                                    styles={colourStyles}
                                    onChange={(selectedOptions) => setSelectedSurfaces(selectedOptions)}
                                    placeholder="Select"
                                    value={selectedSurfaces}
                                />
                            </div>
                            <div style={{ minWidth: "20rem", maxWidth: "30%", width: "fit-content" }} className="d-flex flex-column mr-4 mb-2">
                                <label htmlFor="tournaments">Tournaments</label>
                                <Select
                                    className="dark-theme"
                                    closeMenuOnSelect={false}
                                    isMulti
                                    styles={colourStyles}
                                    options={tournamentOptions}
                                    value={formInputs.selectedTournaments.length > 0 ? tournamentOptions.filter(t => formInputs.selectedTournaments.includes(t.value)) : []}
                                    onChange={(selectedOptions) => dispatch(setStdReportInputs({ ...formInputs, selectedTournaments: selectedOptions.map((option) => option.value) }))} />
                            </div>
                            <button style={{ width: "10%", minWidth: "fit-content", height: "2.2rem" }} className="btn btn-primary btn-md mt-4 mb-2 text-nowrap" type="button" disabled={loadingReportMatchesCount || invalidInputs} onClick={matchesCountHandler}> {loadingReportMatchesCount ? "Loading" : "Matches Count"}</button>
                        </div>
                        {trackingSheet && <TrackingSheet trackingSheet={trackingSheet} targetLocalName={formInputs.selectedTargetPlayers[0]} />}
                    </div>
                    <div className="modal-footer">
                        <button className="btn btn-secondary btn-md" type="button" data-dismiss="modal">
                            Cancel
                        </button>

                        <button className="btn btn-warning btn-md" type="button"
                            onClick={createReportHandler}
                            disabled={isCreatingStd || invalidInputs || (trackingSheet?.matches.length == 0)}>
                            {isCreatingStd ? "Creating ..." : "Create"}
                        </button>
                    </div>
                </div>
            </div >
        </div >
    );
}
export default NewStdReport;