import { useGetPostMatchListQuery } from "../api/postMatchSlice";
import DataTable from "react-data-table-component";
import Spinner from "../../UI/Spinner";
import { useEffect } from "react";
import {
  selectIsVideoClient,
  selectUser,
  selectIsAllowedPostMatchReport,
} from "../auth/authSlice";
import { useSelector, useDispatch } from "react-redux";
import NotAuthorizedPage from "../../pages/notAuthorizedPage";
import {
  setMatchList,
  setTournament,
  setOpponent,
  setSurface,
  filterMatches,
  selectPostMatchList,
  selectFilteredPostMatchList,
} from "./store/postMatchListSlice";
import classes from "./PostMatchListPage.module.css";

const PostMatchListPage = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const isVideoClient = useSelector(selectIsVideoClient);
  const isAllowedPostMatchReport = useSelector(selectIsAllowedPostMatchReport);
  const postMatchList = useSelector(selectPostMatchList);
  const filteredPostMatchList = useSelector(selectFilteredPostMatchList);
  const tournament = useSelector(
    (state) => state.postMatchList.selectedTournament
  );
  const opponent = useSelector((state) => state.postMatchList.selectedOpponent);
  const surface = useSelector((state) => state.postMatchList.selectedSurface);

  const { data, isLoading, error } = useGetPostMatchListQuery(null, {
    pollingInterval: 30000,
  });

  useEffect(() => {
    if (data) {
      dispatch(setMatchList(data.postMatchList));
    }
  }, [data, dispatch]);

  const opponents = [...new Set(postMatchList?.map((clip) => clip.opponent))];
  const tournaments = [
    ...new Set(postMatchList?.map((clip) => clip.tournament)),
  ];
  const surfaces = [...new Set(postMatchList?.map((clip) => clip.surface))];
  const playerName = data?.playerName;

  const handleTournamentChange = (event) => {
    dispatch(setTournament(event.target.value));
    dispatch(filterMatches());
  };

  const handleOpponentChange = (event) => {
    dispatch(setOpponent(event.target.value));
    dispatch(filterMatches());
  };

  const handleSurfaceChange = (event) => {
    dispatch(setSurface(event.target.value));
    dispatch(filterMatches());
  };

  const columns = [
    {
      name: "Opponent",
      selector: (clip) => clip.opponent,
      width: "30%",
    },
    {
      name: "Tournament",
      selector: (clip) => clip.tournament,
      width: "20%",
    },
    {
      name: "Year",
      selector: (clip) => clip.year,
      width: "15%",
    },
    {
      name: "Round",
      selector: (clip) => clip.round,
      width: "15%",
    },
    {
      name: "Report link",
      selector: (clip) => getReportLink(clip),
      width: "20%",
    },
  ];

  const getReportLink = (clip) => {
    return (
      <a
        className={`${classes["link-column"]}`}
        href={`https://gsapostmatch.azurewebsites.net/gui/match_new/${clip.player}_${clip.matchId}`}
        target="_blank"
      >
        Link
      </a>
    );
  };

  const customPostMatchListTableStyles = {
    table: {
      style: {
        overflowY: "auto",
        overflowX: "auto",
        marginBottom: "20px",
      },
    },
    headCells: {
      style: {
        fontSize: "18px",
        fontWeight: "500",
        color: "#FFFFFF",
        backgroundColor: "black",
        flex: "1",
        justifyContent: "center",
        padding: "48px 24px",
        textTransform: "uppercase",
        borderColor: "#1D1D1D",
        borderStyle: "solid",
        borderWidth: "1px",
        borderBottomWidth: "0px",
      },
    },
    cells: {
      style: {
        color: "#FFFFFF",
        backgroundColor: "rgba(29, 29, 29, 1)",
        flex: "1",
        minWidth: "fit-content",
        justifyContent: "center",
        borderBottom: "1px solid #F2F2F2",
        borderTop: "0px",
        textAlign: "center",
        fontSize: "20px",
        fontWeight: "500",
        height: "95px",
      },
    },
  };

  if (!user) return <Spinner />;
  else if (!isVideoClient || !isAllowedPostMatchReport)
    return <NotAuthorizedPage />;

  let content = null;
  if (isLoading) {
    content = <Spinner />;
  } else if (error) {
    content = <p>{error}</p>;
  } else if (postMatchList) {
    content = (
      <DataTable
        columns={columns}
        data={filteredPostMatchList}
        responsive
        highlightOnHover
        theme="dark"
        customStyles={customPostMatchListTableStyles}
        noDataComponent="No matches available"
      />
    );
  }

  return (
    <div>
      <header>
        <div>
          <img
            src="https://mystifyingeffects.com/GSA/images/haddad.jpg"
            className={`${classes["header-image"]}`}
            alt="background"
          />
        </div>
      </header>
      <div className={`${classes["content-container"]}`}>
        <div className={`${classes["header-container"]}`}>
          <div className={`${classes["left-container"]}`}>
            <h2 className={`${classes["player-name"]}`}> {playerName}</h2>{" "}
            <p className={`${classes["subheader"]}`}>
              LIST OF POST MATCH REPORTS
            </p>
          </div>
          <div className={`${classes["right-container"]}`}>
            <select value={tournament} onChange={handleTournamentChange}>
              <option value="">Tournaments</option>
              {tournaments.map((tournament) => (
                <option key={tournament} value={tournament}>
                  {tournament}
                </option>
              ))}
            </select>
            <select value={opponent} onChange={handleOpponentChange}>
              <option value="">Opponents</option>
              {opponents.map((opponent) => (
                <option key={opponent} value={opponent}>
                  {opponent}
                </option>
              ))}
            </select>
            <select value={surface} onChange={handleSurfaceChange}>
              <option value="">Surfaces</option>
              {surfaces.map((surface) => (
                <option key={surface} value={surface}>
                  {surface}
                </option>
              ))}
            </select>
          </div>
        </div>
        {content}
      </div>
    </div>
  );
};

export default PostMatchListPage;
