import { createSlice, current } from "@reduxjs/toolkit";

const initialPostMatchListState = {
  postMatchList: [],
  filteredPostMatchList: [],
  selectedTournament: null,
  selectedOpponent: null,
  selectedSurface: null,
};

const postMatchListSlice = createSlice({
  name: "postMatchList",
  initialState: initialPostMatchListState,
  reducers: {
    setMatchList(state, action) {
      const newState = { ...current(state) };
      const matchList = [...action.payload];
      matchList.sort((a, b) => new Date(b.DATE) - new Date(a.DATE));
      const filteredMatchList = getFilteredMatchList(
        matchList,
        newState.selectedTournament,
        newState.selectedOpponent,
        newState.selectedSurface
      );

      state = {
        ...state,
        postMatchList: matchList,
        filteredPostMatchList: filteredMatchList,
      };

      return state;
    },
    setTournament(state, action) {
      state.selectedTournament = action.payload;
    },
    setOpponent(state, action) {
      state.selectedOpponent = action.payload;
    },
    setSurface(state, action) {
      state.selectedSurface = action.payload;
    },
    filterMatches(state) {
      state.filteredPostMatchList = getFilteredMatchList(
        state.postMatchList,
        state.selectedTournament,
        state.selectedOpponent,
        state.selectedSurface
      );
    },
  },
});

export const getFilteredMatchList = (
  matchList,
  tournament,
  opponent,
  surface
) => {
  const results = matchList.filter(
    (match) =>
      (!tournament || match.tournament === tournament) &&
      (!opponent || match.opponent === opponent) &&
      (!surface || match.surface === surface)
  );
  return results;
};

export const {
  setMatchList,
  setTournament,
  setOpponent,
  setSurface,
  filterMatches,
} = postMatchListSlice.actions;

export const selectPostMatchList = (state) => state.postMatchList.postMatchList;
export const selectFilteredPostMatchList = (state) =>
  state.postMatchList.filteredPostMatchList;
export const selectTournament = (state) =>
  state.postMatchList.selectedTournament;
export const selectOpponent = (state) => state.postMatchList.selectedOpponent;
export const selectSurface = (state) => state.postMatchList.selectSurface;

export default postMatchListSlice.reducer;
