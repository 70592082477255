import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "./features/api/apiSlice";
import { completeMatchesSlice } from "./features/api/completeMatchesSlice";
import { reportsSlice } from "./features/api/reportsSlice";
import authReducer from "./features/auth/authSlice";
import ranksSlice from "./features/ranks/ranksSlice";
import matchesSlice from "./features/completeMatches/store/matchesSlice";
import { userSlice } from "./features/api/userSlice";
import videoPlayerSlice from "./features/completeMatches/store/videoPlayerSlice";
import reportGenerationSlice from "./features/reportGeneration/store/reportGenerationSlice";
import postMatchListSlice from "./features/postMatchList/store/postMatchListSlice";
import { postMatchSlice } from "./features/api/postMatchSlice";
const store = configureStore({
  reducer: {
    auth: authReducer,
    ranks: ranksSlice,
    shotsVideoPlayer: videoPlayerSlice,
    completeMatches: matchesSlice,
    reportGeneration: reportGenerationSlice,
    postMatchList: postMatchListSlice,
    [apiSlice.reducerPath]: apiSlice.reducer,
    [completeMatchesSlice.reducerPath]: completeMatchesSlice.reducer,
    [reportsSlice.reducerPath]: reportsSlice.reducer,
    [userSlice.reducerPath]: userSlice.reducer,
    [postMatchSlice.reducerPath]: postMatchSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(
      apiSlice.middleware,
      completeMatchesSlice.middleware,
      reportsSlice.middleware,
      userSlice.middleware,
      postMatchSlice.middleware
    );
  },
});

export default store;
