import { useState, useEffect } from "react";
import {
  useCreateCustomClipMutation,
  useLazyCheckCustomClipExistsQuery,
} from "../../api/completeMatchesSlice";
import {
  getFilteredMatches,
  selectMatchesPointList,
  selectSelectedPointsIds,
  selectPrevShotsCount,
  selectNextShotsCount
} from "../store/videoPlayerSlice";
import { useSelector } from "react-redux";

const MAX_POINTS_IN_CLIP = 50;

const CustomClipModal = ({ onCloseModal }) => {
  const matchesPointList = useSelector(selectMatchesPointList);
  const selectedPointsIds = useSelector(selectSelectedPointsIds);
  const prevShotsCount = useSelector(selectPrevShotsCount);
  const nextShotsCount = useSelector(selectNextShotsCount);

  const [clipName, setClipName] = useState(null);
  const [nameIsValid, setNameIsValid] = useState(false);
  const [createCustomClip, { isLoading, isError, isSuccess }] =
    useCreateCustomClipMutation();

  const [
    checkCustomClipExists,
    { data: clipExists, isLoading: checkIsLoading },
  ] = useLazyCheckCustomClipExistsQuery();

  useEffect(() => {
    if (clipName === null) return;
    const identifier = setTimeout(() => {
      checkCustomClipExists(clipName);
    }, 300);
    return () => clearTimeout(identifier);
  }, [clipName]);

  useEffect(() => {
    if (clipName === null) return;
    const nameIsValid =
      clipName.trim().length > 0 &&
      !checkIsLoading &&
      clipExists != undefined &&
      !clipExists;
    setNameIsValid(nameIsValid);
  }, [clipName, checkIsLoading, clipExists]);

  useEffect(() => {
    toastr.options.positionClass = "toast-bottom-right";
    if (isSuccess)
      toastr.success(
        "Custom Clip Request Created Successfully!",
        "Create Custom Clip Request"
      );
    else if (isError)
      toastr.error(
        "Failed to create Custom clip Request!",
        "Create Custom Clip Request"
      );
  }, [isSuccess, isError]);

  const createCustomClipHandler = () => {
    let filteredMatches = getFilteredMatches(matchesPointList, prevShotsCount, nextShotsCount);
    filteredMatches = filteredMatches.map((match) => {
      return {
        ...match,
        pointList: match.pointList.filter((point) =>
          selectedPointsIds.includes(point.pointId)
        ),
      };
    }).filter((match) => match.pointList.length > 0);

    const customClipModel = {
      clipName: clipName,
      matchesCount: filteredMatches.length,
      pointsCount: filteredMatches.reduce(
        (total, match) => total + match.pointList.length,
        0
      ),
      shotsCount: filteredMatches.reduce(
        (total, match) =>
          total +
          match.pointList.reduce(
            (total, point) => total + point.shots.filter(s => s.isSelected).length,
            0
          ),
        0
      ),
      targetParts: getMergedTargetParts(filteredMatches),
    };
    createCustomClip(customClipModel);
  };

  const getMergedTargetParts = (filteredMatches) => {
    return filteredMatches.flatMap((match) =>
      match.pointList.flatMap((point) => {
        const shots = point.shots;
        const mergedShots = [];

        let currentStart = null;
        let currentEnd = null;
        let currentPath = null;
        let lastShotNumber = null;

        shots.forEach((shot, index) => {
          if (currentStart === null) {
            currentStart = shot.startTime;
            currentEnd = shot.endTime;
            currentPath = match.matchVideoUrl;
            lastShotNumber = shot.shotNumber;
          } else if (shot.shotNumber === lastShotNumber + 1) {
            currentEnd = shot.endTime;
            lastShotNumber = shot.shotNumber;
          } else {
            mergedShots.push({
              start: currentStart,
              end: currentEnd,
              path: currentPath,
            });
            currentStart = shot.startTime;
            currentEnd = shot.endTime;
            currentPath = match.matchVideoUrl;
            lastShotNumber = shot.shotNumber;
          }

          if (index === shots.length - 1) {
            mergedShots.push({
              start: currentStart,
              end: currentEnd,
              path: currentPath,
            });
          }
        });

        return mergedShots;
      })
    );
  };

  return (
    <div
      className="modal fade"
      id="customClipModal"
      role="dialog"
      aria-labelledby="customClipModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h3 className="modal-title" id="customClipModalLabel">
              Create Video Clip
            </h3>
            <button
              className="close"
              type="button"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span className="font-weight-light" aria-hidden="true">
                &times;
              </span>
            </button>
          </div>
          <div className="modal-body">
            <div className="mb-3">
              {selectedPointsIds.length > MAX_POINTS_IN_CLIP ? (
                <p className="text-danger">
                  {selectedPointsIds.length} points are selected. The maximum number of points that can be included in a clip is {MAX_POINTS_IN_CLIP}!
                </p>
              ) : <p>{selectedPointsIds.length} points are selected.</p>}
            </div>
            <p className="mb-3">Please enter clip name:</p>
            <input
              className="form-control"
              id="name"
              type="text"
              onChange={(e) => setClipName(e.target.value)}
              placeholder="Clip Name"
            />
            {isSuccess && (
              <p className="mt-3 mb-1 text-success">
                Successfully created custom clip!
              </p>
            )}
            {isError && (
              <p className="mt-3 mb-1 text-danger">
                Failed to create custom clip!
              </p>
            )}
            {!isSuccess && clipExists && clipName.trim().length > 0 && (
              <p className="mt-3 mb-1 text-danger">
                Custom clip with this name already exists!
              </p>
            )}
          </div>
          <div className="modal-footer">
            <button
              className="btn btn-secondary btn-md"
              type="button"
              data-dismiss="modal"
              onClick={() => onCloseModal(false)}
            >
              {isSuccess ? "Ok" : "Cancel"}
            </button>
            <button
              className="btn btn-warning btn-md"
              type="button"
              disabled={
                !nameIsValid || isLoading || checkIsLoading || isSuccess || selectedPointsIds.length > MAX_POINTS_IN_CLIP
              }
              onClick={createCustomClipHandler}
            >
              {isLoading ? "Creating Clip..." : "Create Clip"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomClipModal;
