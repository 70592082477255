import classes from "../ReportGenerationPage.module.css"

const BGTrackingSheet = ({ trackingSheet, targetPlayer }) => {

    const getOpponentData = (match) => {
        const isPlayer1 = match.p1 === targetPlayer;
        const opponentName = isPlayer1 ? match.p1 : match.p2;
        return (
            <>
                <td>{opponentName}</td>
            </>
        );
    }

    return (
        <div style={{ marginRight: "-15px", marginLeft: "-15px" }} className="container-fluid d-flex flex-column align-items-center">
            <br />
            <div className="col-4 alert alert-secondary text-center font-weight-bold lead mb-2" role="alert">
                {trackingSheet.length} Matches
            </div>
            <br />
            <div style={{ width: "100%" }}>
                <table className={`table table-striped table-bordered table-hover table-sm mb-4 ${classes['table-auto']}`}>
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Year</th>
                            <th scope="col">Tournament</th>
                            <th scope="col">Round</th>
                            <th scope="col">Opponent</th>
                        </tr>
                    </thead>
                    <tbody>
                        {trackingSheet.map((match, index) => (
                            <tr className="text-align-center" key={`match-${index}`}>
                                <td>{index + 1}</td>
                                <td>{match.year}</td>
                                <td>{match.tournament}</td>
                                <td>{match.round}</td>
                                {getOpponentData(match)}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default BGTrackingSheet;
